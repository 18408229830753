import React, { useState, useRef } from 'react';
import axios from 'axios';
import './PublishCardPage.css';
import CustomDialog from './CustomDialog'; // 引入自定义弹出框
import { CKEditor } from '@ckeditor/ckeditor5-react'; // 引入 CKEditor
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; // 引入 Classic 编辑器构建

const PublishRentCardPage = () => {
  const [cardData, setCardData] = useState({
    district: '',
    roomType: '',
    traffic: '',
    price: '',
    accoRequirements: '',
    surrFacilities: '',
    contact: '',
    checkInTime: '',
    rentPeriod: '',
    note: '',
  });
  const [selectedImages, setSelectedImages] = useState([]); // 选中的本地图片文件
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // 控制弹出框的状态
  const [dialogMessage, setDialogMessage] = useState(''); // 弹出框显示的消息
  const [dialogTitle, setDialogTitle] = useState(''); // 弹出框的标题
  const [isSubmitting, setIsSubmitting] = useState(false); // 控制按钮禁用状态

  // 创建一个 ref 来访问文件输入元素
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardData({ ...cardData, [name]: value });
  };

  const handleImageChange = (e) => {
    setSelectedImages(e.target.files); // 保存用户选择的图片文件
  };

  // 上传图片到服务器
  const uploadImages = async () => {
    const token = localStorage.getItem('token');
    const uploadedImageUrls = [];

    if (selectedImages.length === 0) {
      return '';
    }

    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append('image', selectedImages[i]);

      try {
        const response = await axios.post('http://romi.one:8080/common/api/resource/image', formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data) {
          uploadedImageUrls.push(response.data); // 将每个返回的图片 URL 保存
        }
      } catch (err) {
        setDialogTitle('Error');
        setDialogMessage('Image upload failed, please try again.');
        setIsDialogOpen(true);
        return null;
      }
    }

    return uploadedImageUrls.join(', ');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (isSubmitting) return;

    setIsSubmitting(true);

    const token = localStorage.getItem('token');

    const uploadedImageUrls = await uploadImages();
    if (uploadedImageUrls === null) {
      setIsSubmitting(false);
      return;
    }

    const payload = {
      district: cardData.district,
      roomType: cardData.roomType,
      traffic: cardData.traffic,
      price: parseFloat(cardData.price),
      accoRequirements: cardData.accoRequirements,
      surrFacilities: cardData.surrFacilities,
      contact: cardData.contact,
      checkInTime: cardData.checkInTime,
      rentPeriod: parseInt(cardData.rentPeriod, 10),
      publishTime: new Date().toISOString(), // 自动生成发布时间
      images: uploadedImageUrls || '',
      note: cardData.note,
    };

    try {
      const response = await axios.post('http://romi.one:8080/ireland-social/api/house-info', payload, {
        headers: {
          Authorization: token,
        },
      });

      if (response.status === 201) {
        setDialogTitle('Success');
        setDialogMessage('Card published successfully!');
        setIsDialogOpen(true);
        setCardData({
          district: '',
          roomType: '',
          traffic: '',
          price: '',
          accoRequirements: '',
          surrFacilities: '',
          contact: '',
          checkInTime: '',
          rentPeriod: '',
          note: '',
        });
        setSelectedImages([]);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } catch (err) {
      setDialogTitle('Error');
      setDialogMessage('Failed to publish the card. Please try again.');
      setIsDialogOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeDialog = () => {
    setCardData({
      district: '',
      roomType: '',
      traffic: '',
      price: '',
      accoRequirements: '',
      surrFacilities: '',
      contact: '',
      checkInTime: '',
      rentPeriod: '',
      note: '',
    });
    setSelectedImages([]);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setIsDialogOpen(false);
  };

  return (
    <div className="card-publish-container">
      <h1 className="title">Publish a Rent Room Card</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="card-form">
        <div className="form-group">
          <label>District:</label>
          <input
            type="text"
            name="district"
            value={cardData.district}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Room Type:</label>
          <input
            type="text"
            name="roomType"
            value={cardData.roomType}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Traffic:</label>
          <input
            type="text"
            name="traffic"
            value={cardData.traffic}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Price:</label>
          <input
            type="number"
            name="price"
            value={cardData.price}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Accommodation Requirements:</label>
          <textarea
            name="accoRequirements"
            value={cardData.accoRequirements}
            onChange={handleChange}
            className="input-field textarea"
          />
        </div>
        <div className="form-group">
          <label>Surrounding Facilities:</label>
          <textarea
            name="surrFacilities"
            value={cardData.surrFacilities}
            onChange={handleChange}
            className="input-field textarea"
          />
        </div>
        <div className="form-group">
          <label>Contact:</label>
          <input
            type="text"
            name="contact"
            value={cardData.contact}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Check-in Time:</label>
          <input
            type="datetime-local"
            name="checkInTime"
            value={cardData.checkInTime}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Rent Period (months):</label>
          <input
            type="number"
            name="rentPeriod"
            value={cardData.rentPeriod}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Note:</label>
          <textarea
            name="note"
            value={cardData.note}
            onChange={handleChange}
            className="input-field textarea"
          />
        </div>
        <div className="form-group">
          <label>Upload Images:</label>
          <input
            type="file"
            name="images"
            onChange={handleImageChange}
            multiple // 允许选择多张图片
            className="input-field"
            ref={fileInputRef} // 绑定 ref
          />
        </div>
        <div className="form-group">
          <button type="submit" className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? 'Publishing...' : 'Publish Card'}
          </button>
        </div>
      </form>

      {/* 自定义弹出框 */}
      <CustomDialog 
        isOpen={isDialogOpen} 
        onClose={closeDialog} 
        title={dialogTitle} 
        message={dialogMessage} 
      />
    </div>
  );
};

export default PublishRentCardPage;
